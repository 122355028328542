<template>
    <tr class="income-total-base-table__row income-total-base-table__row_head">
        <th class="income-total-base-table__cell income-total-base-table__cell_head income-total-base-table__cell_sortable" scope="col"
            v-for="column in columns"
            :key="column.id"
            @click="setIncomeSorting(column.id)"
        >
            <div class="income-total-base-table__cell-content income-total-base-table__cell-content_columns"
                :class="`income-total-base-table__cell-content_${column.alignment}`"
            >
                <span class="income-total-base-table__value">
                    <template v-if="column.id === 'prediction'">Прогноз выручки<br></template>
                    {{column.title}}
                </span>
                <svg-icon v-if="field === column.id" class="income-total-base-table__value income-total-base-table__value_icon"
                    :icon="direction === 'ASC' ? 'chevron-up' : 'chevron-down'"
                />
            </div>
        </th>
    </tr>
</template>

<script>
    import { mapMutations, mapState } from "vuex";

    export default {
        name: "IncomeTotalBaseTableHeadRow",
        props: {
            columns: {
                type: Array,
                required: true
            }
        },
        computed: {
            ...mapState({
                field: state => state.income.sorting.field,
                direction: state => state.income.sorting.direction,
            }),
        },
        methods: {
            ...mapMutations([
                "setIncomeSorting",
            ]),
        }
    };
</script>
