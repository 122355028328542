<template>
    <div class="income-total">
        <income-settings class="income-total__settings" />
        <spinner v-if="status === 'loading'" class="income-total__spinner" />
        <error-message v-else-if="status === 'error'" class="income-total__error-message">
            Не удалось загрузить данные отчета "Доходы".
        </error-message>
        <p v-if="status === 'no-restaurants'">Выберите хотя бы один ресторан.</p>
        <section v-else-if="status === 'success'" class="income-total__data">
            <h2 class="income-total__data-heading sr-only">Данные отчета "Доходы"</h2>
            <income-total-base-table v-if="modeId === 'base'"
                :columns="columns"
                :totalRow="reportIncomeRestaurants.length > 1 ? reportIncomeTotal : null"
                :rows="reportIncomeRestaurants"
            />
            <template v-else-if="modeId === 'compareOtherPeriod'">
                <p class="income-total__error" v-if="!compareIncome">Выберите период сравнения.</p>
                <template v-else>
                    <income-compare-table
                        v-if="reportIncomeRestaurants.length > 1"
                        :columns="columns"
                        :reportRow="reportIncomeTotal"
                        :compareRow="compareIncomeTotal"
                    />
                    <income-compare-table
                        v-for="restaurant in reportIncomeRestaurants"
                        :key="restaurant.id"
                        :columns="columns"
                        :reportRow="restaurant"
                        :compareRow="findRestaurant(compareIncomeRestaurants, restaurant.id)"
                    />
                </template>
            </template>
            <template v-else-if="modeId === 'comparePlan'">
                <income-compare-table
                    v-if="reportIncomeRestaurants.length > 1"
                    :columns="planColumns"
                    :reportRow="reportIncomeTotal"
                    :compareRow="reportIncomeTotalPlanPrediction"
                />
                <income-compare-table
                    v-for="restaurant in reportIncomeRestaurants"
                    :key="restaurant.id"
                    :columns="planColumns"
                    :reportRow="restaurant"
                    :compareRow="getRestaurantPlan(restaurant)"
                />
            </template>
            <template v-else-if="modeId === 'dynamic'">
                <p class="income-total__error" v-if="!dynamicIncome">Выберите период динамики.</p>
                <template v-else>
                    <income-dynamic-table
                        v-if="reportIncomeRestaurants.length > 1"
                        :columns="dynamicColumns"
                        :reportRow="reportIncomeTotal"
                        :dynamicRow="dynamicIncomeTotal"
                    />
                    <income-dynamic-table
                        v-for="restaurant in reportIncomeRestaurants"
                        :key="restaurant.id"
                        :columns="dynamicColumns"
                        :reportRow="restaurant"
                        :dynamicRow="findRestaurant(dynamicIncomeRestaurants, restaurant.id)"
                    />
                </template>
            </template>
        </section>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
    import { isDaterangeSet, daterangeText, daterangeKey } from "@/helpers/daterange";
    import { compareStrings, compareNumbers } from "@/helpers/compare";
    import IncomeSettings from "./common/IncomeSettings";
    import IncomeTotalBaseTable from "./income-total/IncomeTotalBaseTable";
    import IncomeCompareTable from "./common/IncomeCompareTable";
    import IncomeDynamicTable from "./common/IncomeDynamicTable";

    export default{
        name: "IncomeTotal",
        components: {
            IncomeSettings,
            IncomeTotalBaseTable,
            IncomeCompareTable,
            IncomeDynamicTable,
        },
        data() {
            return {
                status: "loading",
            };
        },
        computed: {
            ...mapState({
                reportDaterange: state => state.report.daterange,
                reportRestaurants: state => state.report.restaurants,
                compareDaterange: state => state.income.compareDaterange,
                dynamicDaterange: state => state.income.dynamicDaterange,
                modeId: state => state.income.modeId,
                sorting: state => state.income.sorting,
                data: state => state.income.data,
            }),
            ...mapGetters([
                "userRestaurants",
                "reportRestaurantsKey",
                "reportKey",
            ]),
            requiredDateranges() {
                let dateranges = [this.reportDaterange];

                if (this.modeId === "compareOtherPeriod" && isDaterangeSet(this.compareDaterange)) {
                    dateranges.push(this.compareDaterange);
                }

                if (this.modeId === "dynamic" && isDaterangeSet(this.dynamicDaterange)) {
                    dateranges.push(this.dynamicDaterange);
                }

                return dateranges;
            },
            requiredIncomes() {
                return this.requiredDateranges?.map(daterange => this.data[`${daterangeKey(daterange)}-${this.reportRestaurantsKey}`]);
            },
            reportIncome() {
                return this.data[this.reportKey];
            },
            reportIncomeTotal() {
                if (this.reportIncome?.total?.income) {
                    let incomes = Object.fromEntries(
                        Object.entries(this.reportIncome?.total?.income).map(([serviceType, { value }]) => ([serviceType, value]))
                    );

                    let reportIncomeTotal = {
                        link: {
                            to: {
                                name: "IncomeRestaurant",
                                params: { id: "total" }
                            },
                            title: "Смотреть доходы по всем ресторанам"
                        },
                        title: "Все рестораны",
                        ...incomes,
                        prediction: this.reportIncome?.total?.prediction?.total
                    };

                    if (this.modeId !== "base") {
                        return {
                            ...reportIncomeTotal,
                            caption: reportIncomeTotal.title,
                            title: daterangeText(this.reportDaterange),
                        }
                    }

                    return reportIncomeTotal;
                }

                return this.reportIncome?.total;
            },
            reportIncomeTotalPlanPrediction() {
                return {
                    title: "По плану выручки",
                    total: this.reportIncome?.total?.plan?.sum,
                    prediction: this.reportIncome?.total?.plan?.sum_prediction,
                };
            },
            reportIncomeRestaurants() {
                return this.reportIncome?.restaurants?.map(restaurant => {
                    let incomes = {};

                    if (restaurant?.income) {
                        incomes = Object.fromEntries(
                            Object.entries(restaurant?.income).map(([serviceType, { value }]) => ([serviceType, value]))
                        );
                    }

                    let newRestaurant = {
                        link: {
                            to: {
                                name: "IncomeRestaurant",
                                params: { id: restaurant?.id }
                            },
                            title: `Смотреть доходы по ресторану ${restaurant?.title}`
                        },
                        id: restaurant?.id,
                        title: restaurant?.title,
                        plan: restaurant?.plan,
                        ...incomes,
                        prediction: restaurant?.prediction?.total
                    };

                    let optionalProperties = {};

                    if (this.modeId !== "base") {
                        optionalProperties.title = daterangeText(this.reportDaterange);
                        optionalProperties.caption = restaurant?.title;
                    }

                    return {
                        ...newRestaurant,
                        ...optionalProperties
                    };
                })?.sort((restaurant1, restaurant2) => {
                    let compare = this.sorting.field === "title" ? compareStrings: compareNumbers;

                    return compare(restaurant1[this.sorting.field], restaurant2[this.sorting.field], this.sorting.direction);
                });
            },
            compareIncome() {
                if (isDaterangeSet(this.compareDaterange)) {
                    return this.data[`${daterangeKey(this.compareDaterange)}-${this.reportRestaurantsKey}`];
                }
            },
            compareIncomeTotal() {
                if (this.compareIncome?.total?.income) {
                    let incomes = Object.fromEntries(
                        Object.entries(this.compareIncome?.total?.income).map(([serviceType, { value }]) => ([serviceType, value]))
                    );

                    return {
                        title: daterangeText(this.compareDaterange),
                        ...incomes,
                        prediction: this.compareIncome?.total?.prediction?.total
                    };
                }
            },
            compareIncomeRestaurants() {
                return this.compareIncome?.restaurants?.map(restaurant => {
                    let newRestaurant = {
                        id: restaurant?.id,
                        title: daterangeText(this.compareDaterange),
                    };

                    let incomes = {};

                    if (restaurant?.income) {
                        incomes = Object.fromEntries(
                            Object.entries(restaurant?.income).map(([serviceType, { value }]) => ([serviceType, value]))
                        );
                    }

                    return {
                        ...newRestaurant,
                        ...incomes,
                        prediction: restaurant?.prediction?.total
                    };
                })
            },
            dynamicIncome() {
                if (isDaterangeSet(this.dynamicDaterange)) {
                    return this.data[`${daterangeKey(this.dynamicDaterange)}-${this.reportRestaurantsKey}`];
                }
            },
            dynamicIncomeTotal() {
                if (this.dynamicIncome?.total?.income) {
                    let incomes = Object.fromEntries(
                        Object.entries(this.dynamicIncome?.total?.income).map(([serviceType, { value }]) => ([serviceType, value]))
                    );

                    return {
                        title: daterangeText(this.dynamicDaterange),
                        ...incomes,
                        prediction: this.dynamicIncome?.total?.prediction?.total
                    };
                }
            },
            dynamicIncomeRestaurants() {
                return this.dynamicIncome?.restaurants?.map(restaurant => {
                    let newRestaurant = {
                        id: restaurant?.id,
                        title: daterangeText(this.dynamicDaterange),
                    };

                    let incomes = {};

                    if (restaurant?.income) {
                        incomes = Object.fromEntries(
                            Object.entries(restaurant?.income).map(([serviceType, { value }]) => ([serviceType, value]))
                        );
                    }

                    return {
                        ...newRestaurant,
                        ...incomes,
                        prediction: restaurant?.prediction?.total
                    };
                })
            },
            predictionTitle() {
                return this.reportIncome?.prediction_title;
            },
            columns() {
                let columns = [
                    {
                        id: "title",
                        alignment: "left",
                    },
                    {
                        id: "delivery",
                        title: "Доставка",
                        alignment: "right",
                        filter: "currencyIntFormat",
                        difference: { good: "positive" },
                    },
                    {
                        id: "hall",
                        title: "Зал",
                        alignment: "right",
                        filter: "currencyIntFormat",
                        difference: { good: "positive" },
                    },
                    {
                        id: "take",
                        title: "С собой",
                        alignment: "right",
                        filter: "currencyIntFormat",
                        difference: { good: "positive" },
                    },
                    {
                        id: "pickup",
                        title: "Самовынос",
                        alignment: "right",
                        filter: "currencyIntFormat",
                        difference: { good: "positive" },
                    },
                    {
                        id: "total",
                        title: "Всего",
                        alignment: "right",
                        filter: "currencyIntFormat",
                        difference: { good: "positive" },
                    },
                    {
                        id: "prediction",
                        title: this.predictionTitle,
                        alignment: "right",
                        filter: "currencyIntFormat",
                        difference: { good: "positive" },
                    },
                ];

                switch (this.modeId) {
                    case "base":
                        columns[0].title = "Ресторан";
                        break;
                    case "compareOtherPeriod":
                        columns[0].title = "Период";
                        break;
                    case "comparePlan":
                    case "dynamic":
                        columns[0].title = "Источник";
                        break;
                }

                return columns;
            },
            planColumns() {
                return this.columns.filter(({ id }) => ["title", "total", "prediction"].includes(id));
            },
            dynamicColumns() {
                return this.columns.filter(({ id }) => id !== "prediction");
            },
        },
        methods: {
            ...mapMutations([
                "setReportParameter",
            ]),
            ...mapActions([
                "requestIncomes"
            ]),
            findRestaurant(restaurants, id) {
                let restaurant =  restaurants?.find(restaurant => restaurant.id === id);

                if (typeof restaurant === "undefined") {
                    switch (this.modeId) {
                        case "compareOtherPeriod":
                            return {
                                title: daterangeText(this.compareDaterange),
                            };
                        case "dynamic":
                            return {
                                title: daterangeText(this.dynamicDaterange),
                            };
                    }
                }

                return restaurant;
            },
            getRestaurantPlan(restaurant) {
                return {
                    title: "По плану выручки",
                    total: restaurant?.plan?.sum,
                    prediction: restaurant?.plan?.sum_prediction,
                }
            },
            showReport() {
                this.status = "success";
            },
            loadIncomes() {
                this.status = "loading";

                let daterangesWithoutData = this.requiredDateranges.filter(
                    daterange => !Boolean(this.data[`${daterangeKey(daterange)}-${this.reportRestaurantsKey}`])
                );

                this.requestIncomes({
                    dateranges: daterangesWithoutData,
                    restaurants: this.reportRestaurants,
                    restaurantsKey: this.reportRestaurantsKey,
                }).then(() => {
                    this.showReport();
                }).catch(() => {
                    this.status = "error";
                });
            }
        },
        created() {
            if (this.userRestaurants?.ids?.length === 1) {
                this.$router.push({
                    name: "IncomeRestaurant",
                    params:{
                        id: this.userRestaurants?.ids[0]
                    }
                });
            } else {
                if (this.reportRestaurants.length === 0) {
                    this.status = "no-restaurants";
                } else if (this.requiredIncomes?.every(Boolean)) {
                    this.showReport();
                } else {
                    this.loadIncomes();
                }
            }
        },
        watch: {
            requiredIncomes(requiredIncomes) {
                if (this.reportRestaurants?.length === 0) {
                    this.status = "no-restaurants";
                } else if (!requiredIncomes?.every(Boolean)) {
                    this.loadIncomes();
                } else {
                    this.showReport();
                }
            }
        },
    }
</script>

<style lang="scss">
    .income-total__data {
        display: grid;
        grid-gap: 16px;
    }
</style>
